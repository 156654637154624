// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "t_s6 d_gS d_cw d_dv";
export var quoteParagraphCenter = "t_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "t_gV d_gV d_cw d_dx";
export var quoteRowLeft = "t_s7 d_bG";
export var quoteRowCenter = "t_s8 d_bD";
export var quoteRowRight = "t_s9 d_bH";
export var quoteWrapper = "t_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "t_gR d_gR";
export var quoteExceptionSmall = "t_rN p_rN";
export var quoteExceptionNormal = "t_rP p_rP";
export var quoteExceptionLarge = "t_rQ p_rQ";
export var quoteAuthorExceptionSmall = "t_rR p_rR";
export var quoteAuthorExceptionNormal = "t_rS p_rS";
export var quoteAuthorExceptionLarge = "t_rT p_rT";