// extracted by mini-css-extract-plugin
export var alignLeft = "v_qj d_fp d_bG d_dv";
export var alignCenter = "v_bP d_fq d_bD d_dw";
export var alignRight = "v_qm d_fr d_bH d_dx";
export var testimonialsContainer = "v_tb d_dW";
export var testimonialsContainerFull = "v_tc d_dT";
export var testimonialsMainHeader = "v_jw d_jw d_w d_cw";
export var testimonialsComponentParagraph = "v_jy d_jy";
export var testimonialsComponentSmall = "v_jx d_jx";
export var testimonialsComponentsWrapper = "v_js d_js d_y d_w d_cv d_b3 d_b5";
export var testimonialsComponentsWrapperDesign2 = "v_jt d_jt d_y d_w d_cv d_b3 d_b5 d_bC";
export var testimonialsComponentsWrapperDesign3 = "v_jv d_jv d_y d_w d_H d_b5 d_bT d_bz d_bJ d_bP d_bD";
export var testimonialsBackgroundColor = "v_td d_d7";
export var colEqualHeight = "v_tf d_bz";
export var testimonialsColumnWrapper = "v_jz d_jz d_b3";
export var testimonialsImageWrapper = "v_jp d_jp d_w d_Z";
export var testimonialsImageWrapperDesign2 = "v_jq d_jq d_r d_Z d_bx";
export var testimonialsImageWrapperDesign3 = "v_jr d_jr d_Z d_bz";
export var design2TextWrapper = "v_s5 d_bC d_bP d_bJ";
export var design3 = "v_tg d_cD d_H";
export var imageDesign2 = "v_th";
export var SmallSmall = "v_rB p_rB p_qN p_q8";
export var SmallNormal = "v_rC p_rC p_qN p_q9";
export var SmallLarge = "v_rD p_rD p_qN p_q6";
export var exceptionWeight = "v_qL p_rd";