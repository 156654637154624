// extracted by mini-css-extract-plugin
export var carouselContainer = "B_vp d_w d_H d_bf d_Z";
export var carouselContainerCards = "B_vq B_vp d_w d_H d_bf d_Z";
export var carouselContainerSides = "B_vr d_w d_H d_Z";
export var prevCarouselItem = "B_vs d_w d_H d_0 d_k";
export var prevCarouselItemL = "B_vt B_vs d_w d_H d_0 d_k";
export var moveInFromLeft = "B_vv";
export var prevCarouselItemR = "B_vw B_vs d_w d_H d_0 d_k";
export var moveInFromRight = "B_vx";
export var selectedCarouselItem = "B_vy d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "B_vz B_vy d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "B_vB B_vy d_w d_H d_Z d_bf";
export var nextCarouselItem = "B_vC d_w d_H d_0 d_k";
export var nextCarouselItemL = "B_vD B_vC d_w d_H d_0 d_k";
export var nextCarouselItemR = "B_vF B_vC d_w d_H d_0 d_k";
export var arrowContainer = "B_vG d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "B_vH B_vG d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "B_vJ B_vH B_vG d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "B_vK B_vG d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "B_vL";
export var nextArrowContainerHidden = "B_vM B_vK B_vG d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "B_kG d_0";
export var prevArrow = "B_vN B_kG d_0";
export var nextArrow = "B_vP B_kG d_0";
export var carouselIndicatorContainer = "B_vQ d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "B_vR d_w d_bz d_bF";
export var carouselText = "B_vS d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "B_vT B_vS d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "B_vV d_b7";
export var carouselIndicator = "B_vW B_vV d_b7";
export var carouselIndicatorSelected = "B_vX B_vV d_b7";
export var arrowsContainerTopRight = "B_vY d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "B_vZ d_0 d_bl d_bC";
export var arrowsContainerSides = "B_v0 d_0 d_bl d_bC";
export var smallArrowsBase = "B_v1 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "B_v2 B_v1 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "B_v3 B_v2 B_v1 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "B_v4 B_v1 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "B_v5 B_v4 B_v1 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "B_p9";
export var iconSmall = "B_v6";
export var multipleWrapper = "B_v7 d_bC d_bF d_bf";
export var multipleImage = "B_v8 d_bC";
export var sidesPrevContainer = "B_v9 B_v2 B_v1 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "B_wb B_v2 B_v1 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";