// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_sD d_gv d_cs";
export var heroHeaderCenter = "r_gw d_gw d_cs d_dw";
export var heroHeaderRight = "r_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "r_sF d_gr d_cw";
export var heroParagraphCenter = "r_gs d_gs d_cw d_dw";
export var heroParagraphRight = "r_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "r_sG d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "r_sH d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "r_sJ d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "r_sK d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "r_sL d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "r_rG p_rG";
export var heroExceptionNormal = "r_rH p_rH";
export var heroExceptionLarge = "r_rJ p_rJ";
export var Title1Small = "r_rh p_rh p_qN p_qP";
export var Title1Normal = "r_rj p_rj p_qN p_qQ";
export var Title1Large = "r_rk p_rk p_qN p_qR";
export var BodySmall = "r_rw p_rw p_qN p_q5";
export var BodyNormal = "r_rx p_rx p_qN p_q6";
export var BodyLarge = "r_ry p_ry p_qN p_q7";